import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import MDButton from 'mdpr2/components/MDButton';
import { useCallback, useEffect, useState } from 'react';
import useMounted from 'hooks/use-mounted';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import OpenInNew from '@mui/icons-material/OpenInNew';

// WRM
import { useAppContext } from 'contexts/app-context';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import getDateLocaleString from 'utils/get-date-locale-string';
import Notes from 'components/shared/Form/Notes';
import { useUser } from 'contexts/userContext';
import { toApiValues } from 'components/shared/resource/api-form-mapper';
import MDTypography from 'mdpr2/components/MDTypography';
import changeLearningGroupContext from 'utils/changeLearningGroupContext';
import { editPath } from 'utils/learning-group-helpers';
import { downloadQuote, getOrderIdFromIri } from 'utils/order-helpers';
import BasketLines from 'components/shared/Form/BasketLines';
import onboardingApplicationStatusChoices from './OnboardingApplicationStatusChoices';
import LicenceExpiringBanner from './LicenceExpiringBanner';

const EditOnboardingApplicationForm = (props) => {
  const { fields, formValues, getApiError, onFormSubmit, validationSchema, resource: onboardingApplication } = props;
  const infinityPlusSku = 'INFP001';
  const source = 'onboarding-application';

  const [infinityCoreLicence, setInfinityCoreLicence] = useState(null);
  const [infinityPlusProduct, setInfinityPlusProduct] = useState(null);
  const [order, setOrder] = useState(onboardingApplication?.order);
  const [basket, setBasket] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState(formValues);

  const { setShowLoadingSpinner } = useAppContext();
  const { userFeedbackSuccess } = useAppContext();
  const { userProfile, setUserProfile } = useUser();

  const navigate = useNavigate();
  const isMounted = useMounted();

  const orderValidationSchema = Yup.object().shape({
    studentCount: Yup.string().required('Student count is required'),
    urn: Yup.string().required('URN is required'),
    schoolName: Yup.string().required('School name is required'),
    schoolPostcode: Yup.string().required('School postcode is required'),
    dfeNumber: Yup.string().required('DfE is required'),
    basket: Yup.object().shape({
      billingAddress: Yup.object().shape({
        phone: Yup.string().required('Phone number is required'),
        email: Yup.string().required('Email is required'),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
      }),
    }),
  });

  const getLicenceInfo = async (learningGroupIri) => {
    const response = await resourceApi.getResources({
      apiEndpoint: 'licences',
      sortValues: [{ field: 'expiresAt', direction: 'desc' }],
      filterValues: { ownedBy: learningGroupIri, 'membershipPlan.name': 'Infinity Core' },
    });
    if (response.resources.length > 0) {
      setInfinityCoreLicence(response.resources[0]);
    }
  };

  const validateFormForOrder = async (formik) => {
    if (formik.dirty) {
      toast.error('You have unsaved changes');
      return false;
    }
    const validationErrors = [];
    await orderValidationSchema.validate(formik.values, { abortEarly: false }).catch((e) => {
      e.inner.forEach((error) => {
        validationErrors.push(error.message);
      });
    });

    if (validationErrors.length > 0) {
      toast.error(validationErrors.join(', '));
      return false;
    }
    return true;
  };

  const validateBasket = async () => {
    const validateResponse = await resourceApi.saveResource({
      apiEndpoint: 'baskets',
      id: onboardingApplication?.basket?.id,
      extension: 'validate',
      data: {},
    });
    if (validateResponse.violations) {
      toast.error(`Order validation failed`);
      return false;
    }
    return true;
  };

  const handleConvertToQuote = async (formik) => {
    setShowLoadingSpinner(true);
    const formIsValidForCompletion = await validateFormForOrder(formik);
    if (!formIsValidForCompletion) {
      setShowLoadingSpinner(false);
      return;
    }

    const basketIsValid = await validateBasket();
    if (!basketIsValid) {
      setShowLoadingSpinner(false);
      return;
    }

    await resourceApi
      .saveResource({
        apiEndpoint: 'baskets',
        id: onboardingApplication?.basket?.id,
        data: { isQuote: true },
      })
      .then((response) => {
        setBasket(response.resource);
        userFeedbackSuccess('Quote created successfully');
      })
      .catch(() => {
        toast.error('Could not create quote');
      })
      .finally(() => setShowLoadingSpinner(false));
  };

  const handleCreateOrderClick = async (formik) => {
    setShowLoadingSpinner(true);
    const formIsValidForCompletion = await validateFormForOrder(formik);
    if (!formIsValidForCompletion) {
      setShowLoadingSpinner(false);
      return;
    }

    const basketIsValid = await validateBasket();
    if (!basketIsValid) {
      setShowLoadingSpinner(false);
      return;
    }

    try {
      // Create order
      const orderResponse = await requestApi.putResponse({
        url: `baskets/${onboardingApplication?.basket?.id}/convert`,
        data: {},
      });

      // Link order to application
      await resourceApi.saveResource({
        apiEndpoint: 'admin/infinity/onboarding-applications',
        id: onboardingApplication.id,
        data: { order: orderResponse['@id'] },
      });
      setOrder(orderResponse['@id']);
      userFeedbackSuccess('Order created successfully');
      setShowLoadingSpinner(false);
      navigate(`/admin/orders/edit/${orderResponse.id}`);
    } catch (e) {
      toast.error('Could not create order');
      console.log(e);
      setShowLoadingSpinner(false);
    }
  };

  const handleConvertQuoteToOrder = async (formik) => {
    setShowLoadingSpinner(true);
    const formIsValidForCompletion = validateFormForOrder(formik);
    if (!formIsValidForCompletion) {
      setShowLoadingSpinner(false);
      return;
    }
    try {
      // Unset quote
      await resourceApi
        .saveResource({
          apiEndpoint: 'baskets',
          id: onboardingApplication?.basket?.id,
          data: { isQuote: false },
        })
        .then((response) => {
          setBasket(response.resource);
        });
      // Create order
      const orderResponse = await requestApi.putResponse({
        url: `baskets/${onboardingApplication?.basket?.id}/convert`,
        data: {},
      });

      // Link order to application
      await resourceApi.saveResource({
        apiEndpoint: 'admin/infinity/onboarding-applications',
        id: onboardingApplication.id,
        data: { order: orderResponse['@id'] },
      });
      setOrder(orderResponse['@id']);
      userFeedbackSuccess('Order created successfully');
      setShowLoadingSpinner(false);
      navigate(`/admin/orders/edit/${orderResponse.id}`);
    } catch (e) {
      toast.error('Could not create order');
      console.log(e);
      setShowLoadingSpinner(false);
    }
  };

  const handleArchive = async () => {
    await resourceApi.saveResource({
      apiEndpoint: 'admin/infinity/onboarding-applications',
      id: onboardingApplication.id,
      data: { isArchived: true },
    });
    navigate('/admin/infinity/onboarding-applications');
  };

  const handleDuplicate = async () => {
    // Duplication basket but with no licences or discounts
    const basketResponse = await resourceApi.saveResource({
      apiEndpoint: 'baskets',
      data: {
        source,
        user: onboardingApplication.user,
        email: onboardingApplication.email,
        owningLearningGroup: onboardingApplication.learningGroup,
        currency: 'GBP',
        billingAddress: basket.billingAddress,
        deliveryAddress: basket.deliveryAddress,
      },
    });
    const data = toApiValues(onboardingApplication, fields);

    // Custom mappings of IRIs
    data.basket = basketResponse.resource['@id'];
    data.user = onboardingApplication.user['@id'];
    data.learningGroup = onboardingApplication.learningGroup['@id'];

    // Nullify student count
    data.studentCount = null;

    // Create new application and open in new window
    await resourceApi
      .saveResource({
        apiEndpoint: 'admin/infinity/onboarding-applications',
        data,
      })
      .then((response) => {
        window.open(`/admin/infinity/onboarding-applications/edit/${response.resource.id}`);
        // Add note to new application
        resourceApi.saveResource({
          apiEndpoint: 'admin/infinity/onboarding-application-note',
          data: {
            onboardingApplication: `/admin/infinity/onboarding-applications/${response.resource.id}`,
            content: `Duplicated from onboarding application #${onboardingApplication.id}`,
            createdByUser: `users/${userProfile.id}`,
          },
        });
        // Add note to original application
        resourceApi.saveResource({
          apiEndpoint: 'admin/infinity/onboarding-application-note',
          data: {
            onboardingApplication: `/admin/infinity/onboarding-applications/${onboardingApplication.id}`,
            content: `Onboarding application duplicated by #${response.resource.id}`,
            createdByUser: `users/${userProfile.id}`,
          },
        });
      });
  };

  const handleComplete = async () => {
    await resourceApi.saveResource({
      apiEndpoint: 'admin/infinity/onboarding-applications',
      id: onboardingApplication.id,
      data: { completedAt: new Date() },
    });
    navigate('/admin/infinity/onboarding-applications');
  };

  const handleUpdateBasket = async (formik) => {
    await changeLearningGroupContext(onboardingApplication.learningGroup['@id'], setUserProfile);
    const apiValues = toApiValues(formik.values, fields);
    const address = {
      company: apiValues.basket?.billingAddress.company,
      line1: apiValues.basket?.billingAddress.line1,
      line2: apiValues.basket?.billingAddress.line2,
      townCity: apiValues.basket?.billingAddress.townCity,
      postcode: apiValues.basket?.billingAddress.postcode,
      country: apiValues.basket?.billingAddress.country,
      firstName: apiValues.basket?.billingAddress.firstName,
      lastName: apiValues.basket?.billingAddress.lastName,
      phone: apiValues.basket?.billingAddress.phone,
      email: apiValues.basket?.billingAddress.email,
    };
    const basketLines = [
      {
        product: infinityPlusProduct['@id'],
        quantity: 1,
        orderData: [
          {
            name: 'maxStudents',
            label: 'Number of pupils',
            value: apiValues.studentCount,
          },
        ],
      },
    ];

    // Change core product variant id changed max members
    if (formik.values.basket.coreProduct !== formik.initialValues.basket.coreProduct) {
      // Remove old product variant
      if (formik.initialValues.basket.coreProduct) {
        basketLines.push({
          productVariant: formik.initialValues.basket.coreProduct,
          quantity: 0,
        });
      }
      // Add new product variant
      if (formik.values.basket.coreProduct) {
        basketLines.push({
          productVariant: formik.values.basket.coreProduct,
          quantity: 1,
        });
      }
    }

    await resourceApi
      .saveResource({
        apiEndpoint: 'baskets',
        id: basket?.id,
        data: {
          source,
          billingAddress: address,
          deliveryAddress: address,
          manualDiscount: apiValues.basket?.manualDiscount,
          basketLines,
        },
      })
      .then((response) => {
        setBasket(response.resource);
      });
  };

  const getStatus = () => {
    const statusChoice = onboardingApplicationStatusChoices.find(
      (status) => status.value === onboardingApplication.status
    );
    return statusChoice?.label ?? '';
  };

  const handleSave = async (formik) => {
    if (!basket.isQuote && !order) {
      await handleUpdateBasket(formik);
    }
    await formik.handleSubmit();
    setInitialFormValues(formik.values);
  };

  const getBasket = async () => {
    await resourceApi
      .getResource({
        apiEndpoint: 'baskets',
        id: onboardingApplication.basket.id,
      })
      .then((response) => {
        setBasket(response);
      });
  };

  const getInfinityProduct = async () => {
    await resourceApi
      .getResources({
        apiEndpoint: 'products',
        filterValues: { sku: infinityPlusSku },
      })
      .then((response) => {
        if (response.resources.length === 0) {
          toast.error('Could not find infinity plus product');
          return;
        }
        setInfinityPlusProduct(response.resources[0]);
      });
  };

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      await getInfinityProduct();
      if (onboardingApplication?.learningGroup['@id']) {
        await getLicenceInfo(onboardingApplication?.learningGroup['@id']);
      }
      await getBasket();
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(async () => {
    await initialise();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title={`Onboarding Application #${onboardingApplication.id}`} />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="start" alignItems="center" gap={1}>
                            <MDTypography variant="h6">Learning Group: </MDTypography>
                            <MDTypography variant="body2">
                              {onboardingApplication?.learningGroup?.name ?? ''}
                            </MDTypography>
                            <MDButton
                              onClick={() => window.open(editPath(onboardingApplication?.learningGroup))}
                              size="medium"
                            >
                              <OpenInNew />
                            </MDButton>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="start" alignItems="center" gap={1}>
                            <MDTypography variant="h6">Status: </MDTypography>
                            <MDTypography variant="body2">
                              {getStatus()}
                              {onboardingApplication.accessRequestSentAt
                                ? ` at ${getDateLocaleString(onboardingApplication.accessRequestSentAt)}`
                                : ''}
                            </MDTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          {fields.map(
                            (mappedField) =>
                              mappedField.name === 'estimatedGoLiveBy' && (
                                <FormField
                                  {...mappedField}
                                  key={mappedField.name}
                                  formik={formik}
                                  getApiError={getApiError}
                                />
                              )
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="Wonde details" />
                    <CardContent>
                      <Grid container spacing={2}>
                        {fields.map(
                          (mappedField) =>
                            mappedField.section === 'wondeInfo' && (
                              <FormField
                                {...mappedField}
                                key={mappedField.name}
                                formik={formik}
                                getApiError={getApiError}
                              />
                            )
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="User details" />
                    <CardContent>
                      <Grid container spacing={2}>
                        {fields.map(
                          (mappedField) =>
                            mappedField.section === 'user' && (
                              <FormField
                                {...mappedField}
                                key={mappedField.name}
                                formik={formik}
                                getApiError={getApiError}
                                disabled={!!(basket?.isQuote || order)}
                              />
                            )
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="Billing address" />
                    <CardContent>
                      <Grid container spacing={2}>
                        {fields.map(
                          (mappedField) =>
                            mappedField.section === 'billing' && (
                              <FormField
                                {...mappedField}
                                key={mappedField.name}
                                formik={formik}
                                getApiError={getApiError}
                                type={basket?.isQuote || order ? 'static' : mappedField.type}
                              />
                            )
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="Licence details" />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
                      <LicenceExpiringBanner licence={infinityCoreLicence} membershipPlanName="Infinity Core" />
                      <Box display="flex" alignItems="center" sx={{ gap: '0.75rem' }}>
                        <FormField
                          {...fields.find((field) => field.name === 'studentCount')}
                          formik={formik}
                          getApiError={getApiError}
                          disabled={!!(basket?.isQuote || order)}
                        />
                        {fields.map(
                          (mappedField) =>
                            mappedField.section === 'order' && (
                              <FormField
                                {...mappedField}
                                key={mappedField.name}
                                formik={formik}
                                getApiError={getApiError}
                                disabled={!!(basket?.isQuote || order)}
                              />
                            )
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                {basket && !order && (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <BasketLines basket={basket} setBasket={setBasket} entityName="basket" disableRemoveItems />
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Card>
                    <CardActions>
                      <Box display="flex" justifyContent="flex-start" width="100%" gap="1rem">
                        <MDButton color="info" variant="gradient" onClick={() => handleSave(formik)}>
                          Save & Update Prices
                        </MDButton>
                        {basket?.isQuote && (
                          <MDButton
                            color="secondary"
                            variant="gradient"
                            onClick={() => downloadQuote(onboardingApplication.basket?.id)}
                          >
                            Download Quote
                          </MDButton>
                        )}
                        {basket?.isQuote && !order && (
                          <MDButton color="info" variant="gradient" onClick={() => handleConvertQuoteToOrder(formik)}>
                            Convert to Order
                          </MDButton>
                        )}
                        {!basket?.isQuote && !order && (
                          <>
                            <MDButton color="info" variant="gradient" onClick={() => handleConvertToQuote(formik)}>
                              Create Quote
                            </MDButton>
                            <MDButton color="info" variant="gradient" onClick={() => handleCreateOrderClick(formik)}>
                              Create Order
                            </MDButton>
                          </>
                        )}
                        {order && (
                          <MDButton
                            color="secondary"
                            variant="gradient"
                            onClick={() => navigate(`/admin/orders/edit/${getOrderIdFromIri(order)}`)}
                          >
                            Go to Order #1{getOrderIdFromIri(order)}
                          </MDButton>
                        )}
                      </Box>
                      <Box display="flex" justifyContent="flex-end" width="100%" gap="1rem">
                        {order && (
                          <MDButton color="success" variant="gradient" onClick={() => handleComplete()}>
                            Complete Application
                          </MDButton>
                        )}
                        <MDButton color="secondary" variant="gradient" onClick={() => handleDuplicate(formik)}>
                          Duplicate
                        </MDButton>
                        <MDButton color="secondary" variant="gradient" onClick={() => handleArchive()}>
                          Delete Application
                        </MDButton>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Notes
                data={{ onboardingApplication: onboardingApplication['@id'] }}
                inputNotes={onboardingApplication.onboardingApplicationNotes}
                apiEndpoint="admin/infinity/onboarding-application-note"
                title="Notes"
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EditOnboardingApplicationForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  refreshResource: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default EditOnboardingApplicationForm;
